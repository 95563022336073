import React from 'react';

import * as Styled from './styles';
import { StyledProps } from './styles';

interface Props extends StyledProps {
  title: string;
  subtitle?: string;
  rmSeparator?: boolean;
}

const TitleSection: React.FC<Props> = ({ center, title, subtitle, rmSeparator }) => (
  <Styled.TitleSection>
    <Styled.Title center={center}>{title}</Styled.Title>
    {subtitle && <Styled.SubTitle center={center}>{subtitle}</Styled.SubTitle>}
    {!rmSeparator && <Styled.Separator center={center} />}
  </Styled.TitleSection>
);

export default TitleSection;
