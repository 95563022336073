import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Styled from './styles';

import { ImageSharpFluid } from 'helpers/definitions';

const Logo: React.FC = () => {
  const { site, portraitImage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      portraitImage: file(relativePath: { eq: "Silva_portrait.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const logoTitle: string = site.siteMetadata.title;

  return (
    <>
      <Styled.Text>{logoTitle}</Styled.Text>
    </>
  );
};

export default Logo;
