import styled from 'styled-components';
import tw from 'twin.macro';
import Link from 'components/ui/Link';

export const ImageLink = styled(Link)`
  ${tw`text-indigo-900 hover:text-indigo-900`};
`;

export const Text = styled.h1`
  ${tw`text-xl mr-6`};
`;

export const Image = styled.figure`
  ${tw`w-16 h-16 mr-3 border border-blue-500 rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;
